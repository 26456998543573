<template>
  <div class="textarea-wrapper" :class="{ 'exist-value': value || isFocused }">
    <label :for="id">{{ label }}</label>
    <textarea
      :name="id"
      :id="id"
      :cols="cols"
      :rows="rows"
      :value="value"
      :placeholder="placeholder"
      :maxlength="maxlength - 1"
      ref="textarea"
      :class="{ 'overflow-hidden': isResizable }"
      @input="onChange"
      @focus="isFocused = true"
      @blur="isFocused = false"
      @keydown="resize(id)"
      @keyup="resize(id)"
    />
    <p v-if="isDisplayLength" class="num-text" :class="{ 'error-text': !isValueValid }">
      {{ value.length }}/{{ maxlength }}
    </p>
  </div>
</template>

<script>
import resizeHeight from '@/asset/js/resizeHeight';

export default {
  name: 'TextareaBox',
  props: {
    label: {
      type: String,
      require: false,
      default: '',
    },
    cols: {
      type: String,
      require: false,
      default: '',
    },
    rows: {
      type: String,
      require: false,
      default: '',
    },
    name: {
      type: String,
      require: false,
      default: '',
    },
    id: {
      type: String,
      require: false,
      default: '',
    },
    placeholder: {
      type: String,
      require: false,
      default: '',
    },
    value: {
      type: String,
      require: false,
      default: '',
    },
    maxlength: {
      type: Number,
      require: false,
      default: null,
    },
    isDisplayLength: {
      type: Boolean,
      required: false,
      default: false,
    },
    isResizable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isFocused: false,
    };
  },
  computed: {
    isValueValid() {
      return this.value.length < this.maxlength;
    },
  },
  mounted() {
    this.resize();
  },
  methods: {
    onChange(event) {
      this.$emit('input', event.target.value);
    },
    resize() {
      if (this.isResizable) {
        return resizeHeight(this.$refs['textarea']);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'TextareaBox';
</style>
