var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "textarea-wrapper",
      class: { "exist-value": _vm.value || _vm.isFocused },
    },
    [
      _c("label", { attrs: { for: _vm.id } }, [_vm._v(_vm._s(_vm.label))]),
      _c("textarea", {
        ref: "textarea",
        class: { "overflow-hidden": _vm.isResizable },
        attrs: {
          name: _vm.id,
          id: _vm.id,
          cols: _vm.cols,
          rows: _vm.rows,
          placeholder: _vm.placeholder,
          maxlength: _vm.maxlength - 1,
        },
        domProps: { value: _vm.value },
        on: {
          input: _vm.onChange,
          focus: function ($event) {
            _vm.isFocused = true
          },
          blur: function ($event) {
            _vm.isFocused = false
          },
          keydown: function ($event) {
            return _vm.resize(_vm.id)
          },
          keyup: function ($event) {
            return _vm.resize(_vm.id)
          },
        },
      }),
      _vm._v(" "),
      _vm.isDisplayLength
        ? _c(
            "p",
            {
              staticClass: "num-text",
              class: { "error-text": !_vm.isValueValid },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.value.length) +
                  "/" +
                  _vm._s(_vm.maxlength) +
                  " "
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }